// prettier-ignore
import { Button, Grid, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import { projectUtil } from '../../utils/project.utils';
import HeadCard from '../../components/headCard';
import ToggleMenu from '../../components/toggleMenu';

import { useDispatch, useSelector } from 'react-redux';
import { projectActions } from '../../redux/actions/project-actions';
import { filterActions } from '../../redux/actions/filters-actions';
import { taskActions } from '../../redux/actions/task-actions';

import styles from './styles';
import Storage from '../../utils/storage';
import ContractualInfo from './contractualInfo';
import AdditionalInfo from './additionalInfo';
import { TaskLevel } from './taskLevel';

const ProjectInfo = ({ classes }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const projectId = useParams().id;
  const currentUser = useSelector(state => state.authentication.user);
  const project = useSelector(state => state.projects.project);
  const countries = useSelector(state => state.filters.countries);
  const taskLevels = useSelector(state => state.tasks.taskLevels);
  const projectTaskLevel = useSelector(state => state.tasks.projectTaskLevel);
  const tasksImportanceFactorsUpdated = useSelector(state => state.tasks.importanceFactorsUpdated);
  const contractsImportanceFactorsUpdated = useSelector(state => state.contracts.importanceFactorsUpdated);
  const areImportanceFactorsUpdated = tasksImportanceFactorsUpdated || contractsImportanceFactorsUpdated;
  const [countryLevels, setCountryLevels] = useState(null);
  const [selectedCfValues, setSelectedCfValues] = useState([]);
  const [currentValue, setCurrentValue] = useState(Storage.getData('projectInfoIndex', null, 0));
  const itemsList = intl.messages['projectInfo.product.toggleMenu'];

  const administrativeLevelModel = {
    principal_level: null,
    level_container: null,
  };

  useEffect(() => {
    if (currentUser && projectId) {
      dispatch(filterActions.requestCountries(currentUser.client_id));
      dispatch(taskActions.getProjectTaskLevels(projectId));
    }
  }, [currentUser, projectId, projectTaskLevel]);

  useEffect(() => {
    if (currentUser && projectId && areImportanceFactorsUpdated) {
      dispatch(projectActions.getProjectDetails(currentUser.client_id, projectId));
    }
  }, [areImportanceFactorsUpdated]);

  useEffect(() => {
    if (!!project) {
      if (!selectedCfValues?.length) {
        setSelectedCfValues(projectUtil.cfValuesState(project));
      }
      if (!countryLevels && !!countries?.length) {
        setCountryLevels(selectedAdministrativeLevels(project, countries));
      }
    }
  }, [project]);

  const handleChangeToggleMenu = value => {
    const valueAux = parseInt(value);
    Storage.setData('projectInfoIndex', valueAux);
    setCurrentValue(valueAux);
  };

  /*
  Input: countries (Array of Objects), countryName (String)
  Output: Array with the name of country levels
  Constraint: !!countries and levels have to be ordered
  */
  const countryLevelsFunc = (countries, countryId) => {
    let array = [];
    countries.forEach(country => {
      if (country.id === countryId) {
        Object.entries(country).forEach(([key, value]) => {
          if (key.startsWith('level') && !!value) {
            array.push(value);
          }
        });
      }
    });
    return array;
  };

  /*
  Input: project (Object)
  Output: Array with all administrative levels of the project
  Constraint: 
  */
  const administrativeLevels = project => {
    let array = [];
    Object.entries(project).forEach(([key, value]) => {
      if (key.startsWith('administrative') && !!value.length) {
        array.push(value);
      }
    });
    return array;
  };

  /*
  Input: project (Object), countries (Array of Objects)
  Output: Array with selected country's Administrative Levels
  Constraint: !!project and !!countries
  */
  const selectedAdministrativeLevels = (project, countries) => {
    const cLevel = countryLevelsFunc(countries, project['countries'][0]?.id);
    const aLevel = administrativeLevels(project);

    let array = [];
    let clone = null;

    cLevel.forEach((value, index) => {
      clone = Object.assign({}, administrativeLevelModel);
      clone.principal_level = value;
      clone.level_container = Object.assign({}, aLevel[index]);
      array.push(clone);
    });
    return array;
  };
  const levelsNames = ['one', 'two', 'three'];
  const currentLevelValue = currentValue - 1;
  const currentLevel = taskLevels[currentLevelValue] || null;
  const currentParentLevel = taskLevels[currentValue - 2] || null;

  const handleAddProjectTaskLevel = () => {
    const params = { level_type: levelsNames[currentLevelValue] };

    dispatch(taskActions.createProjectTaskLevel(params, projectId));
  };

  return (
    <Grid container className={`${classes.root}`}>
      <Link className={`${classes.root}-link`} to='/projects'>
        <FormattedMessage id='project.allProjects' defaultMessage={'All Projects'} />
      </Link>
      {!!project && (
        <>
          <HeadCard cfValue={selectedCfValues[0]} name={project.name} code={project.external_id} id={project.id} />
          <ToggleMenu
            itemsList={itemsList}
            currentValue={currentValue}
            handleChangeToggleMenu={handleChangeToggleMenu}
          />
          <Grid container>
            {currentValue === 0 && <ContractualInfo project={project} />}
            {currentLevel && (
              <TaskLevel
                classes={classes}
                project={project}
                currentUser={currentUser}
                level={currentLevel}
                parentLevel={currentParentLevel}
              />
            )}
            {!currentLevel && currentValue > 0 && currentValue < 4 && (
              <Grid container margin={3} alignItems='center' flexDirection='column' spacing={1}>
                <Typography variant='subtitle1'>
                  <FormattedMessage
                    id='projectInfo.notProjectLevelAdded'
                    defaultMessage=' Aún no ha sido agregado el nivel a este proyecto.'
                  />
                </Typography>
                <Grid container marginTop={1}>
                  <Grid item margin='auto'>
                    <Button variant='outlined' color='info' onClick={handleAddProjectTaskLevel}>
                      <FormattedMessage id='projectInfo.notProjectLevelAdd' defaultMessage='Agregar Nivel' />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {currentValue === 4 && <AdditionalInfo />}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(ProjectInfo);
