import { Button, Chip, CircularProgress, FormControl, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';

import { withStyles } from '@mui/styles';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';

import styles from './styles';
import TooltipZoom from '../../../../components/tooltipZoom';
import NumberInput from '../../../../components/numberField';
import { taskActions } from '../../../../redux/actions/task-actions';
import { contractActions } from '../../../../redux/actions/contract-actions';

const AssignWeightsModal = ({ classes, items = [], type, label = '', product, parentId }) => {
  const initialImportanceFactorsHash = items.reduce((obj, { id, importance_factor }) => {
    obj[id] = importance_factor;
    return obj;
  }, {});
  const isATask = type !== 'contract';
  const intl = useIntl();
  const dispatch = useDispatch();
  const [importanceFactors, setImportanceFactors] = useState(initialImportanceFactorsHash);
  const [openModal, setOpenModal] = useState(false);
  const loading = false;
  const { importance_factor } = product;

  const handleUpdateTasksWeights = mappedImportanceFactors => {
    dispatch(taskActions.updateTaskWeights(mappedImportanceFactors, parentId));
  };

  const handleUpdateContractsWeights = mappedImportanceFactors => {
    dispatch(contractActions.updateContractsWeights(mappedImportanceFactors, parentId));
  };

  const handleConfirmButton = () => {
    const mappedImportanceFactors = Object.entries(importanceFactors).map(([key, value]) => ({
      id: Number(key),
      importance_factor: value,
    }));

    if (isATask) {
      handleUpdateTasksWeights(mappedImportanceFactors);
    } else {
      handleUpdateContractsWeights(mappedImportanceFactors);
    }
    setOpenModal(false);
  };

  const handleWeightChange = (e, currentId) => {
    setImportanceFactors({ ...importanceFactors, ...{ [currentId]: Number(e.target.value) } });
  };

  const handleCancelButton = () => {
    setOpenModal(false);
  };

  const totalValue = Object.values(importanceFactors).reduce((sum, item) => {
    return item + sum;
  }, 0);

  const sumIsValid = totalValue === 100;

  const tasksUI = (
    <Grid container className={`${classes.task}_body`} marginTop={2}>
      {items.map((item, idx) => (
        <Grid container alignItems='end' justifyContent='space-between' key={idx}>
          <Grid item>
            <Typography variant='h5' color='text.secondary' className={`${classes.approve}_label`}>
              {isATask && `${type} ${item.nested_positions}`}
              {!isATask && `${intl.messages['mainContractLabel.contract']} ${idx + 1}:  ${item.description}`}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems='end' spacing={1}>
              <Grid item>
                <FormControl className={`${classes.advance}_label`}>
                  <NumberInput value={importanceFactors[item.id]} onChange={e => handleWeightChange(e, item.id)} />
                </FormControl>
              </Grid>
              <Grid item>
                <Typography variant='h5' color='text.secondary'>
                  {'%'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const errorMessageUI = (
    <Grid container spacing={1} alignItems='revert'>
      <Grid item>
        <Typography variant='h5' color='error.main'>
          <ErrorOutlineRounded fontSize='large' />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h5' color='text.secondary'>
          <FormattedMessage id='task.ErrorMessage' defaultMessage='La suma de los factores no es igual al 100%' />
        </Typography>
      </Grid>
    </Grid>
  );

  const bodyUI = (
    <Grid container className={`${classes.task}_body`}>
      <Grid item marginTop={-2}>
        <Typography variant='h6' color='text.secondary'>
          <FormattedMessage id='task.importantFactor' defaultMessage='Factor de importancia' />
        </Typography>
        <Typography variant='h6' color='text.secondary'>
          <FormattedMessage id='task.message' defaultMessage='Message importance factor' />
        </Typography>
      </Grid>
      {tasksUI}
      <Grid container>
        <Grid item marginLeft='auto' marginRight={1}>
          <Typography variant='h5' color='text.secondary'>
            Total:{' '}
            <Typography variant='h5' component='span' color={sumIsValid ? 'secondary.main' : 'error.light'}>
              {totalValue} %
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      {!sumIsValid && errorMessageUI}
    </Grid>
  );

  const buttonsUI = (
    <Grid container className={`${classes.task}_buttons`}>
      <Button variant='contained' onClick={handleConfirmButton} disabled={!sumIsValid}>
        <FormattedMessage id='projectInfo.approve.assignNewFactors' defaultMessage={'Confirm'} />
      </Button>
      <Button variant='outlined' onClick={handleCancelButton}>
        <FormattedMessage id='projectInfo.approve.cancel' defaultMessage='Cancel' />
      </Button>
    </Grid>
  );

  const headerUI = (
    <Grid container className={`${classes.approve}_head`}>
      <Grid container alignContent='space-between' alignItems='center'>
        <Grid item>
          <Typography variant='h4' color='text.secondary'>
            <FormattedMessage id='task.importanceFactor' defaultMessage='Asignar factor de importancia' />
          </Typography>
        </Grid>
        <Grid item marginLeft='auto'>
          <IconButton color='primary' aria-label='close task modal' onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item marginBottom={4}>
        <Typography variant='h4' color='text.primary' className={`${classes.approve}_label`}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <TooltipZoom
        title={
          <FormattedMessage id='projectInfo.tasks.assignImportanceFactorMessage' defaultMessage='Review progress' />
        }
      >
        <Chip variant='filled' color='primary' onClick={() => setOpenModal(true)} label={`${importance_factor} %`} />
      </TooltipZoom>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Grid className={`${classes.approve}_modal`}>
          {loading && (
            <Stack alignItems='center' justifyContent='center' width='100%' height='50%'>
              <CircularProgress size={100} />
            </Stack>
          )}
          {!loading && (
            <>
              {headerUI}
              {bodyUI}
              {buttonsUI}
            </>
          )}
        </Grid>
      </Modal>
    </>
  );
};

export default withStyles(styles)(AssignWeightsModal);
