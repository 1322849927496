import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { taskActions } from '../../../../redux/actions/task-actions';

export const ParentSelector = ({ parentId, setParentId, parentLevel, required = true, onParentChange = () => {} }) => {
  const dispatch = useDispatch();
  const { name: parentLevelName, id: parenLevelId } = parentLevel || {};
  const parentTasks = useSelector(state => state.tasks.parentTaskList);
  const intl = useIntl();

  useEffect(() => {
    if (!isEmpty(parentLevel)) {
      dispatch(taskActions.getProjectParentTasks(parenLevelId));
    }
  }, [parenLevelId]);

  const handleChangeResult = event => {
    setParentId(event.target.value);
  };

  const selectLabelUI = (
    <Typography variant='inherit' color='text.secondary'>
      {intl.messages[`projectInfo.${parentLevelName}.select`]}{' '}
      {required && (
        <Typography component='span' color='red'>
          *
        </Typography>
      )}
    </Typography>
  );

  const renderFoundValue = selected => {
    const { nested_positions: foundParentTask, contracts = [] } = parentTasks.find(task => task.id === selected) || {};

    if (!isEmpty(contracts)) {
      onParentChange(contracts);
    }

    return (
      <>
        {foundParentTask && (
          <Typography textTransform='capitalize'>{`${parentLevelName} ${foundParentTask}`}</Typography>
        )}
      </>
    );
  };

  return (
    <>
      <InputLabel id='select-parent-label'>{selectLabelUI}</InputLabel>
      <Select
        displayEmpty
        labelId='select-parent-label'
        id='select-parent-input'
        required={required}
        value={parentId}
        label={selectLabelUI}
        onChange={handleChangeResult}
        renderValue={renderFoundValue}
      >
        {!isEmpty(parentTasks) &&
          parentTasks.map((task, index) => (
            <MenuItem key={`Result_${index}_${task.id}`} value={task.id}>
              <Typography textTransform='capitalize'>{`${parentLevelName} ${task.nested_positions}`}</Typography>
            </MenuItem>
          ))}
      </Select>
    </>
  );
};
