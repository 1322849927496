import PropTypes from 'prop-types';
import { Button, Card, CardContent, CardMedia, CircularProgress, Grid, Menu, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';

import styles from './styles';
import ContractProgressBars from './contractProgressBars';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { projectUtil } from '../../utils/project.utils';
import ChangeStatusModal from './changeStatusModal';

export const contractStatuses = {
  in_negotiation: {
    label: <FormattedMessage id='labelState.negotiation' defaultMessage='En negociacion' />,
  },
  in_progress: {
    label: <FormattedMessage id='labelState.inProgress' defaultMessage='En Ejecución' />,
  },
  finished: {
    label: <FormattedMessage id='labelState.complete' defaultMessage='Concluido' />,
  },
  suspended: {
    label: <FormattedMessage id='labelState.suspended' defaultMessage='Suspendido' />,
  },
};

const HeadCard = ({ classes, img, name, id, description }) => {
  const project = useSelector(state => state.projects.project);
  const contractsLoading = useSelector(state => state.contracts.loading);
  const mainContract = project?.main_contract;
  const currentUser = useSelector(state => state.authentication.user);
  const currentStatus = contractStatuses[mainContract?.status];
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const open = Boolean(anchorEl);
  const currentStatuses = Object.fromEntries(
    Object.entries(contractStatuses).filter(([key]) => key !== mainContract?.status)
  );
  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeStatus = status => {
    setOpenStatusModal(true);
    setSelectedStatus(status);
  };

  return (
    <Card className={`${classes.card}`}>
      {img && <CardMedia className={`${classes.card}-media`} component='img' alt="Project's Picture" image={img} />}
      <CardContent className={`${classes.card}_content`}>
        <Grid container className={`${classes.card}_content_title_wrapper`}>
          {!!name && (
            <Grid item className={`${classes.card}_content_title_name`}>
              {name}
            </Grid>
          )}
          <Grid item>
            {!!currentStatus && (
              <>
                <Button
                  className={`${classes.card}_chip ${classes.card}_chip_${mainContract.status}`}
                  onClick={handleClick}
                  endIcon={<ExpandMore />}
                  disabled={!projectUtil.isAPrivilegeUser(currentUser.user_type)}
                >
                  {currentStatus.label}
                </Button>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className={`${classes.card}_status_menu`}
                >
                  {Object.entries(currentStatuses).map(([key, value]) => (
                    <MenuItem
                      onClick={() => {
                        changeStatus(key);
                      }}
                      key={key}
                      className={`${classes.card}_chip_${key} ${classes.card}_status_button `}
                    >
                      {value.label}
                    </MenuItem>
                  ))}
                </Menu>
                <ChangeStatusModal
                  open={openStatusModal}
                  onClose={() => setOpenStatusModal(false)}
                  selectedStatus={selectedStatus}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Grid container>{!!description && description}</Grid>
        {contractsLoading && <CircularProgress color='inherit' size={20} />}
        {!contractsLoading && <ContractProgressBars contract={mainContract} />}
      </CardContent>
    </Card>
  );
};

HeadCard.propTypes = {
  classes: PropTypes.object,
  img: PropTypes.element,
  subtitle: PropTypes.element,
  title: PropTypes.string,
};

export default withStyles(styles)(HeadCard);
