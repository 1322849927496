import { Button, Card, Grid, Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import styles from '../styles';

import { withStyles } from '@mui/styles';
import ContractProgressBars from '../../../../components/headCard/contractProgressBars';
import { FormattedMessage } from 'react-intl';

const SecondaryContracts = ({ classes }) => {
  const contractList = useSelector(state => state.contracts.contractList);
  const secondaryContracts = contractList.filter(contract => contract.main_contract === false);

  const ContractCard = ({ contract }) => (
    <Grid container height='100%'>
      <Grid container marginBottom={2}>
        <Grid item marginLeft='auto'>
          No. {contract.contract_number}
        </Grid>
      </Grid>
      <Grid container>
        <FormattedMessage id='mainContractLabel.currentContractAmount' defaultMessage='Monto del Contrato' />:
      </Grid>
      <Grid container marginBottom={2}>
        {contract.formatted_current_amount}
      </Grid>
      <Grid container marginBottom={2}>
        <Typography className={classes.object}>
          <FormattedMessage id='mainContractLabel.object' defaultMessage='Objeto' />
          :&nbsp;{contract.contract_object}
        </Typography>
        <Grid container>
          <Grid item marginLeft='auto'>
            <Link component='button' variant='body2'>
              <FormattedMessage id='mainContractLabel.showMore' defaultMessage='Ver mas' />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid container marginBottom={3}>
        <ContractProgressBars contract={contract} columns />
      </Grid>
      <Grid container justifyContent='center' marginBottom={2} marginTop='auto'>
        <Button variant='outlined' color='info' className={classes.button}>
          <FormattedMessage id='mainContractLabel.viewContract' defaultMessage='VER CONTRATO' />
        </Button>
      </Grid>
    </Grid>
  );

  const secondaryContractsUI = (
    <Grid container spacing={2} marginBottom={3}>
      {secondaryContracts.map(contract => (
        <Grid item>
          <Card className={`${classes.card}${classes.card}_contract`}>
            <ContractCard contract={contract} />
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const emptyMessage = (
    <Grid container justifyContent='center' alignItems='center' marginTop={5} className={classes.simpleText}>
      <FormattedMessage id='mainContractLabel.emptyContracts' defaultMessage='Aún no se ha agregado ningún contrato.' />
    </Grid>
  );

  return <Grid container>{isEmpty(secondaryContracts) ? emptyMessage : secondaryContractsUI}</Grid>;
};
export default withStyles(styles)(SecondaryContracts);
